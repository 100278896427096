<div
  class="modal-wrapper p-6 candidate-form-wrapper"
  [formGroup]="candidateForm"
>
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">Edit Details</h2>
      <p class="textXS mt-2 text">Enter details of candidate</p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <input type="hidden" formControlName="positionID" />
  <input type="hidden" formControlName="candidateID" />
  <div class="content-wrapper my-4">
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Name <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="name"
        />
      </mat-form-field>
    </div>
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Email Address <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input matInput placeholder="Enter here" formControlName="email" />
      </mat-form-field>
      <span
        *ngIf="
          candidateForm.get('email')?.invalid &&
          (candidateForm.get('email')?.touched ||
            candidateForm.get('email')?.dirty)
        "
        class="required-star"
        >Email is required and must be valid.</span
      >
    </div>
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Notice Period <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appNumericInput
          matInput
          placeholder="Enter here"
          formControlName="noticePeriod"
        />
      </mat-form-field>
      <span
        class="required-star"
        *ngIf="
          candidateForm.get('noticePeriod')?.invalid &&
          (candidateForm.get('noticePeriod')?.touched ||
            candidateForm.get('noticePeriod')?.dirty)
        "
        >Field is required.</span
      >
    </div>
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Status <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Select" formControlName="status" required>
          <mat-option value="Interview">Interview</mat-option>
          <mat-option value="screening">Screening</mat-option>
          <mat-option value="Selected">Selected</mat-option>
          <mat-option value="Rejected">Rejected</mat-option>
        </mat-select>
      </mat-form-field>

      <span
        class="required-star"
        *ngIf="
          candidateForm.get('status')?.invalid &&
          (candidateForm.get('status')?.touched ||
            candidateForm.get('status')?.dirty)
        "
        >Field is required.</span
      >
    </div>
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Resume <span class="required-star">*</span>
      </p>
      <div
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        [ngClass]="{ 'drop-zone': true, 'drag-over': isDragOver }"
        class="drop-zone"
      >
        <input
          type="file"
          hidden
          (change)="onFileSelected($event)"
          accept=".pdf, image/*"
          #fileInput
        />
        <p class="drop-zone-placeholder" (click)="fileInput.click()">
          <img src="/images/upload.png" alt="" />
        </p>
        <p class="textS mb-4 text-black">Select a file or drag and drop here</p>
        <p class="textXS selected-pdf-info mb-4">
          PDF, file size no more than 10MB
        </p>
      </div>
      <mat-error *ngIf="resumeError" class="text-center textXS text-danger">{{
        resumeError
      }}</mat-error>
      <span
        class="required-star"
        *ngIf="
          candidateForm.get('resume')?.invalid &&
          (candidateForm.get('resume')?.touched ||
            candidateForm.get('resume')?.dirty)
        "
      >
        Field is required.
      </span>
    </div>
    <div
      class="upload-resume-input d-flex justify-between"
      *ngIf="selectedFile"
    >
      <div>
        <p class="mat-body-1 info-text">{{ selectedFile.name }}</p>
      </div>
      <span class="material-symbols-outlined pointer"> publish </span>
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="'Update'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (buttonClick)="onSubmit()"
    ></sft-button>
  </div>
</div>
